<template>
  <v-container class="pa-4 text-center">
    <v-row class="fill-height" align="center" justify="center">
      <template v-for="(item, i) in citems">
        <v-col :key="i" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-img :src="item.img" height="225px">
                <v-card-title class="text-h6 white--text">
                  <v-row class="fill-height flex-column" justify="space-between">
                    <p class="mt-4 subheading text-left">
                      {{ item.assets_name }}
                    </p>

                    <div>
                      <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                        {{ item.identifier }}
                      </p>
                      <p class="text-caption font-weight-medium font-italic text-left">
                        {{ item.subtext }}
                      </p>
                    </div>

                    <div class="align-self-center">
                      <v-btn v-for="(icon, index) in icons" :key="index" :class="{ 'show-btns': hover }"
                        :color="transparent" icon>
                        <v-icon :class="{ 'show-btns': hover }" :color="transparent" @click="openUrl(item.identifier)">
                          {{ icon }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  components: {},
  name: "covid19api",
  data() {
    return {
      icons: ['mdi-view-carousel',],
      default_img: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
      items: [
        {
          assets_name: 'New Releases',
          identifier: `It's New Release Friday`,
          subtext: 'Newly released songs. Updated daily.',
          img: 'https://images.unsplash.com/photo-1429514513361-8fa32282fd5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3264&q=80',
        },
        {
          assets_name: 'Rock',
          identifier: 'Greatest Rock Hits',
          subtext: 'Lose yourself in rock tunes.',
          img: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
        },
        {
          assets_name: 'Mellow Moods',
          identifier: 'Ambient Bass',
          subtext: 'Chill beats to mellow you out.',
          img: 'https://images.unsplash.com/photo-1542320868-f4d80389e1c4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3750&q=80',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
      url: '',
    };
  },
  computed: {
    citems() {
      const _items = [];

      if (this.items != null) {
        this.items.forEach((element) => {
          if (element.img == null) {
            element.img = this.default_img;
          }
        });
      }
      // _items = this.getList();
      // this.items = this.items.concat(_items);
      return this.items;
    }
  },
  methods: {
    ...mapMutations(["setPageTitle"]),
    getList() {
      this.loading = true;
      this.$axios.get(`/trunkserver/assets/allocated-assets/`)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    postData(id) {
      this.$axios
        .post(`/trunkserver/assets/obtain-sa/`, {
          identifier: id,
        })
        .then((res) => {
          this.url = res.data.data.url;
          window.open(this.url,'_blank');
        })
        .catch((error) => {
        });
    },
    handleGetData(element) {
      console.log(element);
    },
    openUrl(id) {
      console.log('open url');
      this.postData(id);

    }
  },
  created() {
    this.setPageTitle("Dashboard");
    this.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
